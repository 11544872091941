import React, { Component } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SliceZone,
} from '../components';
import {
  HtmlContent,
  Wrapper,
} from '../components/common';
import {
  PostHero,
  LatestPosts,
} from '../components/blog';
import './post.scss';

class Post extends Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata,
        },
        post,
      },
      location,
    } = this.props;
    const { data } = post;
    const {
      postTitle,
      date,
      featuredImage,
      featuredImageStyle,
      openingText,
      author,
      body,
      metaTitle,
      metaDescription,
      openGraphImage,
    } = data;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    const { blogSlug } = siteMetadata;
    const authorData = author.document.data || {};
    const { authorName = null } = authorData;
    const morePostsHeadline = {
      text: 'More Articles',
    };
    return (
      <Layout location={location} seoData={seoData}>
        <article className="blog-post">
          <PostHero
            headline={postTitle}
            author={authorName}
            publishedDate={date}
            featuredImage={featuredImage}
            featuredImageStyle={featuredImageStyle}
          />
          <section className="post-body">
            <Wrapper>
              <div className="container">
                <div className="inner-wrapper">
                  <div className="opening-text">
                    <HtmlContent content={openingText.html} />
                  </div>
                  <SliceZone
                    allSlices={body}
                    location={location}
                  />
                </div>
              </div>
            </Wrapper>
          </section>
        </article>
        <section className="read-more-posts-block">
          <LatestPosts
            headline={morePostsHeadline}
            blogSlug={blogSlug}
            hideButton
          />
        </section>
      </Layout>
    );
  }
}

export default Post;

export const postQuery = graphql`
query PostBySlug($uid: String!) {
  site {
    siteMetadata {
      blogSlug
    }
  },
  post: prismicBlogPost(uid: { eq: $uid }) {
    uid
    data {
      pageTitle: post_title {
        text
      }
      metaTitle: meta_title {
        html
        text
      },
      metaDescription: meta_description {
        html
        text
      },
      openGraphImage: open_graph_image {
        alt
        copyright
        url
      }
      postTitle: post_title {
        text
      }
      featuredImage: featured_image {
        fluid(maxWidth: 1200) {
          ...GatsbyPrismicImageFluid_withWebp
        }
        alt
      }
      featuredImageStyle: feature_image_style
      openingText: opening_text {
        html
      }
      date(formatString: "MMMM D, YYYY")
      category {
        document {
          ... on PrismicBlogCategory {
            uid
            data {
              categoryName: category_name {
                text
              }
            }
          }
        }
      }
      author {
        document {
          ... on PrismicBlogAuthor {
            data {
              authorName: author_name {
                text
              }
            }
          }
        }
      }
      body {
        ... on PrismicBlogPostBodyFeaturedText {
          id
          sliceType: slice_type
          primary {
            textBody: text_body {
              html
            }
          }
        }
        ... on PrismicBlogPostBodyPostVideo {
          id
          sliceType: slice_type
          primary {
            videoProvider: video_provider
            videoUrl: video_url {
              url
            }
            caption {
              text
            }
          }
        }
        ... on PrismicBlogPostBodyRichText {
          id
          sliceType: slice_type
          primary {
            textBody: text_body {
              html
            }
          }
          items {
            buttonLabel: button_label {
              text
            }
            buttonLink: button_link {
              url
            }
          }
        }
        ... on PrismicBlogPostBodyButtons {
          id
          sliceType: slice_type
          buttons: items {
            buttonLabel: button_label {
              text
            }
            buttonLink: button_link {
              url
            }
          }
        }
        ... on PrismicBlogPostBodyPostImage {
          id
          sliceType: slice_type
          primary {
            image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid_withWebp
              }
              alt
            }
            imageCaption: image_caption {
              text
            }
            imageStyle: image_style
          }
        }
      }
    }
  }
}
`;
